<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone4.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
    </header>

    <section class="terms-wrap">

      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Retour</button><br><br>

        <h1 class="big-title">Modalités<br>hébergement</h1>

        <h2 class="small-title">Validité</h2>
        <ul>
          <li class="regular-text">Le rabais s’applique sur les séjours de 2 nuits ou plus.</li>
          <li class="regular-text">Offre valable tous les jours du 11 janvier au 30 avril 2023, à l’exception du 17 février au 4 mars 2023 et du 10 au 18 mars 2023, où les partenaires pourraient ne pas offrir de rabais ou offrir un rabais inférieur à celui stipulé dans cette offre.</li>
          <li class="regular-text">Certains partenaires pourraient ne pas offrir de rabais le samedi ou à certaines autres périodes.</li>
          <li class="regular-text">Offre disponible chez les partenaires hôteliers participants.</li>
          <li class="regular-text">Sujet à disponibilité.</li>
        </ul>

        <h2 class="small-title">Achat</h2>
        <ul>
          <li class="regular-text">L’offre s’applique sur toute réservation effectuée entre le 10 janvier 2023, 13 h, et le 12 janvier 2023, 13 h, pour un séjour de 2 nuits ou plus, du 11 janvier au 30 avril 2023.</li>
          <li class="regular-text">Date de fermeture prévue de la saison de ski 2022-2023 : 16 avril 2023.</li>
          <li class="regular-text">Le séjour doit être réservé au moins 24 heures d’avance.</li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <!-- <ul> -->
          <p class="regular-text">Prépayé et non remboursable.</p>
        <!-- </ul> -->

        <h2 class="small-title">Restrictions</h2>
        <ul>
          <li class="regular-text">Du 17 février au 4 mars 2023 et du 10 au 18 mars 2023, les partenaires hôteliers pourraient ne pas offrir de rabais ou offrir un rabais inférieur à celui stipulé dans cette offre.</li>
          <li class="regular-text">Certains partenaires pourraient ne pas offrir de rabais le samedi ou à certaines autres périodes.</li>
          <li class="regular-text">Cette offre ne peut être jumelée avec d’autres offres ou promotions sur l’hébergement.</li>
          <li class="regular-text">Si le séjour chevauche la période de début ou de fin de validité de l’offre, le rabais sera appliqué sur les nuitées à l’intérieur de la période de validité selon le rabais correspondant au nombre total de nuits du séjour.</li>
          <li class="regular-text">Ces rabais et dates sont modifiables sans préavis.</li>
        </ul>

        <!-- <div class="best-price">
          <img src="@/assets/img/best-price-fr.svg" alt="Meilleur prix garanti Tremblant">
          <p class="regular-text">Si vous trouvez sur Internet une réservation en hébergement à un prix plus avantageux que celui que vous avez réservé auprès de notre service, nous honorerons ce prix dans les 48 heures suivant votre réservation. Vous pouvez donc réserver en toute confiance dès maintenant auprès de notre centrale de réservations ou directement à même notre système de réservation en ligne avec l'assurance de toujours obtenir la garantie du meilleur tarif disponible.</p>
        </div> -->
      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">Lodging<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">Discount applies to a 2-night stay or more. </li>
          <li class="regular-text">Valid for stays between January 11 and April 30, 2023, except from February 17 to March 4, 2023, and from March 10 to 18, 2023, when participating hotels may offer lower discounts than the ones stipulated in this offer, or no rebate at all. </li>
          <li class="regular-text">Some participating lodging partners may not offer discounts on Saturday nights or other specific dates.</li>
          <li class="regular-text">Offer available at participating hotels.</li>
          <li class="regular-text">Subject to availability.</li>
        </ul>

        <h2 class="small-title">Purchase</h2>
        <ul>
          <li class="regular-text">Offer applies to bookings made between January 10, 2023, 1 p.m., and January 12, 2023, 1 p.m., on 2-night stays or more between January 11 and April 30, 2023.</li>
          <li class="regular-text">Scheduled closing date of the 2022-2023 ski season: April 16, 2023.</li>
          <li class="regular-text">Bookings have to be made at least 24 hours prior to the arrival date.</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <p class="regular-text">Prepaid and non-refundable.</p>

        <h2 class="small-title">Restrictions</h2>
          <ul>
            <li class="regular-text">From February 17 to March 4, 2023, and from March 10 to 18, 2023, participating lodging partners may offer lower discounts than the ones stipulated in this offer, or no rebate at all.</li>
            <li class="regular-text">Some participating lodging partners may not offer discounts on Saturday nights or other specific dates.
            <li class="regular-text">This offer cannot be combined with any other offer or promotion on lodging.</li>
            <li class="regular-text">If stay period starts or ends outside of the validity period, discount will be applied on nights included within the validity period only and its value will vary according to the number of nights included in the entire stay.</li>
            <li class="regular-text">These discounts and dates are subject to change without notice.</li>
          </ul>

        <!-- <div class="best-price">
          <img src="@/assets/img/best-price-en.svg" alt="Meilleur prix garanti Tremblant">
          <p class="regular-text">You can reserve any of our lodging properties by visiting <a href="http://tremblant.ca/" target="_blank">tremblant.ca</a> or calling our central reservations office. Part of our mission is also making sure that you get the best price PERIOD. What this means is if you find a better price for your lodging anywhere on the Internet than the one you already reserved with us, we will match that price.</p>
        </div> -->
      </div>
      
    </section>

    <footer-bar></footer-bar>

    <!--Logo Partenaire-->
    <div class="logo-partenaire">
      <a v-if="$route.meta.lang === 'fr'" href="https://www.laurentides.com/fr" target="_blank"
        ><img src="@/assets/img/logo-tl-fr.png" alt="Logo Tourisme Laurentides"
      /></a>
      <a v-else-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"
        ><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"
      /></a>
    </div>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: "Éclaté de haut en bas"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        },
        {
          name: 'twitter:image',
          content: ''
        }
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
